<template>
  <div class="wrapper">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="" name="1">
              <template slot="title">
                <div class="subTit">
                  <el-icon class="el-icon-files"> </el-icon>快捷菜单
                </div>
              </template>
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix"
        :class="collapsed ? 'screen' : ''"
      >
        <el-form class="normalFrom" @submit.native.prevent>
          <div class="clearfix" style="padding: 0 15px">
            <el-row :span="24">
              <el-col
                class="searchRow"
                style="display: flex; align-items: self-start"
              >
                <div class="fl" style="width: 100%">
                  <el-form-item label style="margin-bottom: 10px">
                    <el-input
                      class="search"
                      v-model="searchInfo.searchKey"
                      @keyup.enter.native="getData()"
                      placeholder="请输入项目名称 / 姓名，支持模糊搜索"
                    >
                      <el-button type="primary" slot="append" @click="getData()"
                        >搜 索</el-button
                      >
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <div style="padding-bottom: 10px">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                }"
                :data="ListData"
                class="tabBorder custor"
                v-loading="listLoading"
              >
                <el-table-column label="姓名" width="120">
                  <template #default="scope">
                    <span
                      class="tabHref"
                      @click="showDrawer(scope.row.resumeId)"
                      >{{ scope.row.userName }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column label="项目" show-overflow-tooltip>
                  <template #default="scope">
                    <router-link
                      class="tabHref"
                      target="_blank"
                      :to="{
                        path: '/project/details',
                        query: { id: scope.row.projectId },
                      }"
                      >{{ scope.row.projectName }}
                    </router-link>
                  </template></el-table-column
                >
                <el-table-column label="类型" width="180">
                  <template #default="scope">
                    <span
                      class="tag tag02"
                      v-if="scope.row.inductionStatus.status == '入职保温'"
                      >入职保温</span
                    >
                    <span
                      class="tag tag03"
                      v-if="scope.row.inductionStatus.status == '转正保温'"
                      >转正保温</span
                    >
                    <span
                      class="tag tag15"
                      v-if="
                        scope.row.inductionStatus.status == '入职保温' &&
                        scope.row.inductionStatus.days !== null &&
                        scope.row.inductionStatus.days !== '今日入职'
                      "
                      >{{ scope.row.inductionStatus.days }}天后入职</span
                    >
                    <span
                      class="tag tag11"
                      v-if="
                        scope.row.inductionStatus.status == '入职保温' &&
                        scope.row.inductionStatus.days !== null &&
                        scope.row.inductionStatus.days == '今日入职'
                      "
                      >{{ scope.row.inductionStatus.days }}</span
                    >
                    <span
                      class="tag tag15"
                      v-if="
                        scope.row.inductionStatus.status == '转正保温' &&
                        scope.row.inductionStatus.days !== null &&
                        scope.row.inductionStatus.days !== '今日转正'
                      "
                      >{{ scope.row.inductionStatus.days }}天后转正</span
                    >
                    <span
                      class="tag tag11"
                      v-if="
                        scope.row.inductionStatus.status == '转正保温' &&
                        scope.row.inductionStatus.days !== null &&
                        scope.row.inductionStatus.days == '今日转正'
                      "
                      >{{ scope.row.inductionStatus.days }}</span
                    >
                    <span
                      class="tag tag01"
                      v-if="scope.row.inductionStatus.status == '成功'"
                      >成功</span
                    >
                    <span
                      class="tag tag07"
                      v-if="scope.row.inductionStatus.status == '失败'"
                      >失败</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="entryTime" label="入职/转正时间">
                  <template #default="scope">
                    <span>{{
                      scope.row.entryTime ? scope.row.entryTime : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="serviceFee"
                  label="服务费用"
                ></el-table-column>
                <el-table-column
                  prop="alreadyInvoiceMoney"
                  label="已开票金额"
                ></el-table-column>
                <el-table-column
                  prop="accountArriveMoney"
                  label="已到账金额"
                ></el-table-column>
                <el-table-column label="操作" width="180">
                  <template slot-scope="scope">
                    <span
                      class="tabHref"
                      v-if="scope.row.inductionStatus.status == '入职保温'"
                      @click="freeBack(scope.row, scope.row.projectResumeId)"
                      >反馈入职情况</span
                    ><span
                      class="tabHref"
                      v-if="scope.row.inductionStatus.status == '转正保温'"
                      @click="freeBack(scope.row, scope.row.projectResumeId)"
                      >反馈转正情况</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </section>
    </el-row>
    <!-- 人选详情 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <!-- 反馈入职情况 -->
    <el-dialog
      title="反馈入职情况"
      :visible.sync="dialogVisibleBack"
      width="600px"
      :before-close="dialogVisibleBackClose"
      destroy-on-close
    >
      <el-form
        v-if="dialogVisibleBack"
        @submit.native.prevent
        :model="enrtyForm"
        label-width="150px"
        ref="enrtyForm"
      >
        <el-form-item label="结果：" class="is-required" prop="result">
          <el-select
            v-model="enrtyForm.result"
            @change="changeResult($event)"
            style="width: 360px"
          >
            <el-option value="成功" label="成功入职"></el-option>
            <el-option value="拒绝" label="候选人拒绝"></el-option>
            <el-option value="延期" label="延期入职"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="enrtyForm.result == '成功'">
          <el-form-item
            label="入职时间："
            :rules="[
              { required: true, message: '请选择入职时间', trigger: 'change' },
            ]"
            prop="time"
          >
            <el-date-picker
              v-model="enrtyForm.time"
              type="date"
              :disabled="disabled"
              placeholder="选择日期"
              style="width: 360px"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions2"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="试用期结束："
            :rules="[
              { required: true, message: '请选择时间', trigger: 'change' },
            ]"
            prop="endTimeOfTrial"
            style="margin-bottom: 0"
          >
            <el-date-picker
              v-model="enrtyForm.endTimeOfTrial"
              style="width: 360px"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-checkbox v-model="enrtyForm.addToSchedule"
              >将试用期结束时间添加到日程提醒</el-checkbox
            >
          </el-form-item>
          <el-form-item
            label="约定首次开票时间："
            :rules="[
              { required: true, message: '请选择时间', trigger: 'change' },
            ]"
            prop="conventionFirstMakeInvoiceDate"
            style="margin-bottom: 5px"
          >
            <el-date-picker
              v-model="enrtyForm.conventionFirstMakeInvoiceDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              style="width: 360px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-checkbox
              v-model="enrtyForm.firstMakeInvoiceAddToSchedule"
              disabled
              >将自动添加到日程提醒</el-checkbox
            >
          </el-form-item>
          <el-form-item label="是否设置项目成功：">
            <el-checkbox v-model="enrtyForm.projectFinished">是</el-checkbox>
          </el-form-item>
        </div>
        <div v-if="enrtyForm.result == '拒绝'" style="padding: 0 70px">
          <el-form
            @submit.native.prevent
            label-position="top"
            v-if="CommentWay.columns && CommentWay.columns.length > 0"
          >
            <el-row :gutter="24">
              <el-col
                :span="24"
                v-for="(item, index) in CommentWay.columns"
                :key="item.id"
              >
                <el-form-item
                  :label="index + 1 + '.' + item.title"
                  :required="item.required"
                  class="selectLabel"
                >
                  <div
                    v-if="item.type == '单选型' && item.selectType == '单选'"
                  >
                    <el-radio-group v-model="item.answer">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-radio
                          :label="i"
                          @click.native.prevent="clickItem(i, index)"
                          >{{ i }}</el-radio
                        >
                      </p>
                    </el-radio-group>
                  </div>
                  <div
                    v-if="item.type == '单选型' && item.selectType == '下拉'"
                  >
                    <el-select
                      v-model="item.answer"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(i, _index) in item.options"
                        :key="_index"
                        :label="i"
                        :value="i"
                      ></el-option>
                    </el-select>
                  </div>
                  <div v-if="item.type == '多选型'">
                    <el-checkbox-group v-model="item.answer1">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-checkbox :label="i">{{ i }}</el-checkbox>
                      </p>
                    </el-checkbox-group>
                  </div>
                  <div v-if="item.type == '文本描述型'">
                    <el-input
                      v-model="item.answer"
                      placeholder="请输入内容"
                      type="textarea"
                      :rows="3"
                    ></el-input>
                  </div>
                  <div v-if="item.type == '日期型'">
                    <el-date-picker
                      prefix-icon="el-icon-date"
                      v-model="item.answer"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy/MM/dd"
                    ></el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-if="enrtyForm.result == '延期'">
          <el-form-item
            label="计划入职时间："
            :rules="[
              {
                required: true,
                message: '请选择计划入职时间',
                trigger: 'change',
              },
            ]"
            prop="time"
          >
            <el-date-picker
              v-model="enrtyForm.time"
              type="date"
              placeholder="选择日期"
              style="width: 360px"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-checkbox disabled v-model="enrtyForm.addToSchedule"
              >将计划入职时间添加到日程提醒</el-checkbox
            >
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button
          size="mini"
          @click="
            dialogVisibleBack = false;
            resetForm('enrtyForm');
          "
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="saveEntry()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 反馈转正情况 -->
    <el-dialog
      title="反馈转正情况"
      :visible.sync="dialogVisibleBackzz"
      width="600px"
      destroy-on-close
    >
      <el-form
        @submit.native.prevent
        :model="transferForm"
        label-width="130px"
        ref="transferForm"
      >
        <el-form-item label="结果：" class="is-required" prop="result">
          <el-select
            v-model="transferForm.entryStatus"
            @change="changeResult1($event)"
            style="width: 360px"
          >
            <el-option value="提前转正" label="提前转正"></el-option>
            <el-option value="延期转正" label="延期转正"></el-option>
            <el-option value="未过试用期" label="未过试用期"></el-option>
            <el-option value="试用期离职" label="试用期离职"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="
            transferForm.entryStatus == '提前转正' ||
            transferForm.entryStatus == '延期转正'
          "
          label="新试用期结束："
          :rules="[
            { required: true, message: '请选择时间', trigger: 'change' },
          ]"
          prop="endTimeOfTrial"
          style="margin-bottom: 0"
        >
          <el-date-picker
            v-model="transferForm.endTimeOfTrial"
            style="width: 360px"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions1"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="
            transferForm.entryStatus == '提前转正' ||
            transferForm.entryStatus == '延期转正'
          "
        >
          <el-checkbox v-model="transferForm.addToSchedule"
            >将试用期结束时间添加到日程提醒</el-checkbox
          >
        </el-form-item>
        <div style="padding: 0 70px">
          <el-form
            @submit.native.prevent
            label-position="top"
            v-if="CommentWay1.columns && CommentWay1.columns.length > 0"
          >
            <el-row :gutter="24">
              <el-col
                :span="24"
                v-for="(item, index) in CommentWay1.columns"
                :key="item.id"
              >
                <el-form-item
                  :label="index + 1 + '.' + item.title"
                  :required="item.required"
                  class="selectLabel"
                >
                  <div
                    v-if="item.type == '单选型' && item.selectType == '单选'"
                  >
                    <el-radio-group v-model="item.answer">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-radio
                          :label="i"
                          @click.native.prevent="clickItem(i, index)"
                          >{{ i }}</el-radio
                        >
                      </p>
                    </el-radio-group>
                  </div>
                  <div
                    v-if="item.type == '单选型' && item.selectType == '下拉'"
                  >
                    <el-select
                      v-model="item.answer"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(i, _index) in item.options"
                        :key="_index"
                        :label="i"
                        :value="i"
                      ></el-option>
                    </el-select>
                  </div>
                  <div v-if="item.type == '多选型'">
                    <el-checkbox-group v-model="item.answer1">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-checkbox :label="i">{{ i }}</el-checkbox>
                      </p>
                    </el-checkbox-group>
                  </div>
                  <div v-if="item.type == '文本描述型'">
                    <el-input
                      v-model="item.answer"
                      placeholder="请输入内容"
                      type="textarea"
                      :rows="3"
                    ></el-input>
                  </div>
                  <div v-if="item.type == '日期型'">
                    <el-date-picker
                      prefix-icon="el-icon-date"
                      v-model="item.answer"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy/MM/dd"
                    ></el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-form>
      <span slot="footer">
        <el-button
          size="mini"
          @click="
            dialogVisibleBackzz = false;
            resetForm('transferForm');
          "
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="saveTransfer()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  personalOfferedList,
  latestComment,
  addOrEditEntry,
  offerStatus,
  delayEntry,
  projectresumeFteStatus,
} from "../../api/api";
import { mapGetters } from "vuex";
import resumeDetails from "../../components/resumeDetail";
export default {
  components: { resumeDetails },
  data() {
    return {
      activeNames: ["1", "2"],
      menuList: [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
        {
          name: "团队Pipeline",
          val: "团队Pipeline",
        },
      ],
      actived: "我offer的候选人",
      OrderSearchList: [],
      searchInfo: {
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastModifiedTime",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
        searchKey: "",
      },
      ListData: [],
      listLoading: false,
      drawerVisible: false,
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      dialogVisibleBack: false,
      enrtyForm: {
        result: null,
        time: null,
        endTimeOfTrial: null,
        addToSchedule: true,
        id: 0,
        version: 0,
        projectFinished: false,
        conventionFirstMakeInvoiceDate: null,
        firstMakeInvoiceAddToSchedule: true,
      },
      CommentWay: "",
      freeBackId: "",
      disabled: false,
      dialogVisibleBackzz: false,
      transferForm: {
        addToSchedule: false,
        endTimeOfTrial: null,
        entryStatus: null,
        projectResumeId: null,
      },
      CommentWay1: "",
      defaultTime: null,
      pickerOptions: {
        disabledDate(time) {
          return Date.now() > time.getTime();
        },
      },
      pickerOptions2: {
        disabledDate(time) {
          return Date.now() < time.getTime();
        },
      },
      pickerOptions1: {},
    };
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  created() {
    let permTypes = this.getStore("permiss");
    if (this.$route.query.status) {
      this.actived = this.$route.query.status;
      this.searchInfo.quickType = this.$route.query.status;
    }
    if (permTypes.includes("Pipeline:teamPipeline")) {
      this.menuList = [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
        {
          name: "团队Pipeline",
          val: "团队Pipeline",
        },
      ];
    } else {
      this.menuList = [
        {
          name: "我负责的候选人",
          val: "我负责的候选人",
        },
        {
          name: "我参与项目的候选人",
          val: "我参与项目的候选人",
        },
        {
          name: "我offer的候选人",
          val: "我offer的候选人",
        },
        {
          name: "我提交的审批",
          val: "我提交的审批",
        },
      ];
    }
    this.getData();
  },
  methods: {
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortCheck = null;
        this.sortType = "";
        this.currentItem = "";
        this.listLoading = true;
        if (val == "我offer的候选人") {
          this.$router.push({
            path: "/Pipeline/offerResume",
          });
          return;
        } else if (val == "我提交的审批") {
          this.$router.push({
            path: "/Pipeline/approvalSubmit",
          });
          return;
        } else if (val == "团队Pipeline") {
          this.$router.push({
            path: "/Pipeline/teamPipeline",
          });
          return;
        }
        switch (type) {
          case "quick": //快捷菜单
            this.$router.push({
              path: "/Pipeline",
              query: {
                status: val,
              },
            });
            break;
        }
      }
    },
    //获取表格
    getData() {
      this.listLoading = true;
      personalOfferedList(this.searchInfo).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result;
        }
      });
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerVisible = true;
      this.resumeId = id;
    },
    //关闭抽屉
    handleClose() {
      this.drawerVisible = false;
    },
    //反馈入职结果
    freeBack(data, id) {
      if (data.inductionStatus.status == "入职保温") {
        this.dialogVisibleBack = true;
      } else if (data.inductionStatus.status == "转正保温") {
        this.dialogVisibleBackzz = true;
        this.defaultTime = data.entryTime;
      }
      this.freeBackId = id;
    },
    changeResult(e) {
      if (e == "拒绝") {
        this.getLatestComment();
      } else if (e == "延期") {
        this.enrtyForm.time = "";
        this.enrtyForm.addToSchedule = true;
      } else if (e == "成功") {
        this.enrtyForm.time = new Date().Format("yyyy-MM-dd");
      }
    },
    getLatestComment() {
      latestComment({ type: "Offer" }).then((res) => {
        if (res.success) {
          this.CommentWay = res.result;
          this.CommentWay.columns.forEach((item) => {
            this.$set(item, "answer1", []);
            this.$set(item, "answer", "");
          });
        }
      });
    },
    changeResult1(e) {
      let that = this;
      if (e == "提前转正" || e == "延期转正") {
        if (e == "提前转正") {
          this.pickerOptions1 = {
            disabledDate(time) {
              return time.getTime() > new Date(that.defaultTime).getTime();
            },
          };
        } else {
          this.pickerOptions1 = {
            disabledDate(time) {
              return time.getTime() < new Date(that.defaultTime).getTime();
            },
          };
        }
      } else if (e == "未过试用期") {
      }
      this.getLatestComment1();
    },
    getLatestComment1() {
      latestComment({ type: "入职" }).then((res) => {
        if (res.success) {
          this.CommentWay1 = res.result;
          this.CommentWay1.columns.forEach((item) => {
            this.$set(item, "answer1", []);
            this.$set(item, "answer", "");
          });
        }
      });
    },
    saveEntry() {
      if (!this.enrtyForm.result) {
        this.$message.error("请选择反馈结果");
        return false;
      }
      if (this.enrtyForm.result == "成功") {
        this.$refs["enrtyForm"].validate((valid) => {
          if (valid) {
            addOrEditEntry({
              id: 0,
              objectContent: {
                endTimeOfTrial: this.enrtyForm.endTimeOfTrial,
                entryTime: this.enrtyForm.time,
                addToSchedule: this.enrtyForm.addToSchedule,
                projectFinished: this.enrtyForm.projectFinished,
                conventionFirstMakeInvoiceDate:
                  this.enrtyForm.conventionFirstMakeInvoiceDate,
                firstMakeInvoiceAddToSchedule:
                  this.enrtyForm.firstMakeInvoiceAddToSchedule,
              },
              projectResumeId: this.freeBackId,
              version: this.enrtyForm.version,
            }).then((res) => {
              if (res.success) {
                this.$message.success("反馈成功");
                this.dialogVisibleBack = false;
                this.resetForm("enrtyForm");
                this.getData();
              }
            });
          } else {
            return false;
          }
        });
      } else if (this.enrtyForm.result == "拒绝") {
        let commentRecord = {
          commentColumns: [],
          commentId: this.CommentWay.id,
          id: 0,
          type: this.CommentWay.type,
          version: 0,
        };
        let columns = this.CommentWay.columns;
        for (let i = 0; i < columns.length; i++) {
          if (
            columns[i].required &&
            (columns[i].answer == "" || columns[i].answer == null) &&
            columns[i].answer1.length == 0
          ) {
            this.$message.error("请将必填项填写完整");
            return false;
          }
        }
        columns.map((item) => {
          commentRecord.commentColumns.push({
            columnTitle: item.title,
            commentColumnId: item.id,
            type: item.type,
            value: item.type == "多选型" ? item.answer1.join(",") : item.answer,
          });
        });
        offerStatus({
          id: this.freeBackId,
          offerStatus: "拒绝",
          commentRecord: commentRecord,
        }).then((res) => {
          if (res.success) {
            this.$message.success("反馈成功");
            this.dialogVisibleBack = false;
            this.resetForm("enrtyForm");
            this.getData();
          }
        });
      } else if (this.enrtyForm.result == "延期") {
        this.$refs["enrtyForm"].validate((valid) => {
          if (valid) {
            delayEntry({
              addToSchedule: this.enrtyForm.addToSchedule,
              planEntryTime: this.enrtyForm.time,
              projectResumeId: this.freeBackId,
            }).then((res) => {
              if (res.success) {
                this.$message.success("反馈成功");
                this.dialogVisibleBack = false;
                this.resetForm("enrtyForm");
                this.getData();
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    saveTransfer() {
      if (!this.transferForm.entryStatus) {
        this.$message.error("请选择反馈结果");
        return false;
      } else {
        this.$refs["transferForm"].validate((valid) => {
          if (valid) {
            let commentRecord = {
              commentColumns: [],
              commentId: this.CommentWay1.id,
              id: 0,
              type: this.CommentWay1.type,
              version: 0,
            };
            let columns = this.CommentWay1.columns;
            for (let i = 0; i < columns.length; i++) {
              if (
                columns[i].required &&
                (columns[i].answer == "" || columns[i].answer == null) &&
                columns[i].answer1.length == 0
              ) {
                this.$message.error("请将必填项填写完整");
                return false;
              }
            }
            columns.map((item) => {
              commentRecord.commentColumns.push({
                columnTitle: item.title,
                commentColumnId: item.id,
                type: item.type,
                value:
                  item.type == "多选型" ? item.answer1.join(",") : item.answer,
              });
            });
            projectresumeFteStatus({
              projectResumeId: this.freeBackId,
              commentRecord: commentRecord,
              endTimeOfTrial: this.transferForm.endTimeOfTrial,
              entryStatus: this.transferForm.entryStatus,
              addToSchedule: this.transferForm.addToSchedule,
            }).then((res) => {
              if (res.success) {
                this.$message.success("反馈成功");
                this.dialogVisibleBackzz = false;
                this.resetForm("transferForm");
                this.getData();
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    dialogVisibleBackClose() {
      this.resetForm("enrtyForm");
      this.dialogVisibleBack = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../collapsed.less";
@import "./index.less";
.searchL {
  width: calc(100% - 190px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  // float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    // border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tag {
  margin-right: 8px;
}
</style>
